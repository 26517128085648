.blink_me {
  animation: blinker 1s linear infinite;
  color: #3abd82;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.system {
  font-family: "Aldrich", sans-serif;
}
